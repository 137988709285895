import React, {FC} from 'react'
import {observer} from 'mobx-react'
import clsx from 'clsx'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_PICKER_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from '../../Constants/Defatul'
import moment from 'moment'
//@ts-ignore
import Flatpickr from 'react-flatpickr'
import {Czech} from 'flatpickr/dist/l10n/cs.js'
import 'flatpickr/dist/flatpickr.css'
import {InputLabel} from '../InputLabel'
//@ts-ignore
const CustomInput = ({value, defaultValue, inputRef, placeholder, ...props}) => {
  return (
    <input
      {...props}
      defaultValue={defaultValue}
      placeholder={placeholder}
      className={'form-control form-control-lg' + (props.className ? ' ' + props.className : '')}
      ref={inputRef}
    />
  )
}

const DateTimePickerDefault: FC<{
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  type?: string
  changeValue: any
  multipleSelect?: boolean
  value: any
  readOnly?: boolean
  description?: any
  format?: string
  minDate?: any
  dateFormat?: string
  disable?: any
  displayFormat?: any
  enableTime?: boolean
  classWrapper?: string
  enableDate?: boolean
  isInline?: boolean
  labelAlignTop?: boolean
  labelSize?: string
  hide?: boolean
  disabledInput?: boolean
  allowInput?: boolean
}> = observer((props: any) => {
  let labelClass = []
  let classWrapper = props.classWrapper ?? 'mb-5'
  let inputClass = ['form-select', 'form-select-lg', 'form-control-solid'] //"form-select-solid"
  let format = props.format ? props.format : DEFAULT_DATE_TIME_FORMAT
  let displayFormat = props.displayFormat ? props.displayFormat : DEFAULT_DATE_FORMAT
  let enableTime = props.enableTime ? props.enableTime : false
  let enableDate = props.enableDate ? props.enableDate : true
  let isInline = props.isInline ? props.isInline : false
  let labelAlignTop = props.labelAlignTop ?? false
  let labelSize = props.labelSize ?? 'col-4'
  let disabledInput = props.disabledInput ?? false
  let allowInput = props.allowInput ?? false

  if (props.hide !== undefined && props.hide !== null && props.hide === true) {
    return null
  }

  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  if (props.readOnly) {
    if (!props.value) {
      return null
    }
    return (
      <div className='mb-5 row'>
        {props.label ? (
          <InputLabel
            colSize={labelSize}
            className={`${labelClass.join(' ')} fw-semibold text-muted`}
            readOnly={props.readOnly}
            alignTop={labelAlignTop}
          >
            {props.label}
          </InputLabel>
        ) : null}
        <div className='col-8'>
          <span className=' fs-6 text-dark'>
            {props.value ? moment(props.value).format(displayFormat) : '-'}
          </span>
        </div>
      </div>
    )
  }

  const input = (
    <Flatpickr
      key={props.key}
      disabled
      options={{
        // @ts-ignore
        enableDate: enableDate,
        enableTime: enableTime,
        allowInput: allowInput,
        dateFormat: 'Y-m-d H:i', //props.format ? props.format : DEFAULT_DATE_PICKER_FORMAT,
        altInput: true,
        altFormat: props.format ? props.format : DEFAULT_DATE_PICKER_FORMAT,
        locale: {
          ...Czech,
        },
        minDate: props.minDate ? props.minDate : null,
        disable: props.disable ? props.disable : [],
      }}
      placeholder={props.placeholder ? props.placeholder : ''}
      name={props.name}
      value={props.value}
      onChange={([date]: any) => {
        props.changeValue({
          target: {name: props.name, value: date ? date.toISOString() : null},
        })
      }}
      //@ts-ignore
      render={({defaultValue, value, ...props}, ref) => {
        return (
          // @ts-ignore
          <CustomInput
            name={props.name}
            className={inputClass.join(' ')}
            defaultValue={defaultValue}
            disabled={disabledInput}
            inputRef={ref}
            placeholder={props.placeholder ? props.placeholder : ''}
          />
        )
      }}
    />
  )

  const label = props.label ? (
    <InputLabel className={labelClass.join(' ')} colSize={'col-4'} inlineLabel={isInline}>
      {props.label}
    </InputLabel>
  ) : null

  if (isInline) {
    return (
      <div className={classWrapper}>
        <div className='row'>
          {label}
          <div className={`${label ? 'col-8' : 'col-12'}`}>
            {input}
            {props.formik && props.formik.errors[props.name] ? (
              <div className='fv-plugins-message-container invalid-feedback'>
                <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classWrapper}>
      {label}
      {input}
      {props.formik && props.formik.errors[props.name] ? (
        <div className='fv-plugins-message-container invalid-feedback'>
          <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
        </div>
      ) : null}

      {props.description ? props.description : null}
    </div>
  )
})

export default DateTimePickerDefault
