import {observer} from 'mobx-react'
import React, {FC} from 'react'
import clsx from 'clsx'
import {DefaultBadge} from '../Badge'
import {InputLabel} from '../InputLabel'
import SelectBase from '../SelectBase'

const SelectInline: FC<{
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  type?: string
  changeValue: any
  data: any
  option: any
  multipleSelect?: boolean
  values: any
  readOnly?: boolean
  description?: any
  availableSearch?: boolean
  readOnlyDescription?: boolean
  labelSize?: string
  inputAreaSize?: string
  inputSize?: string
  readOnlyFormat?: any
  hide?: boolean
  inputBold?: boolean
  isCreatable?: boolean
  no_option_message?: string
  disabled?: boolean
  search?: any
}> = observer((props: any) => {
  let labelSize = props.labelSize ?? 'col-4'
  let inputAreaSize = props.inputAreaSize ?? 'col-8'
  let inputSize = props.inputSize ?? 'col-12'
  let labelClass = ['form-label']
  let inputClass = ['form-select', 'form-select-lg', 'form-control-solid'] //"form-select-solid"
  let inputBold = props.inputBold ?? false
  const isDisabled = props.disabled ? props.disabled : false
  const isCreatable = props.isCreatable ? props.isCreatable : false

  if (props.hide !== undefined && props.hide !== null && props.hide === true) {
    return null
  }

  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  if (props.readOnly) {
    let value = props.values[props.name]
    let values: any = []

    if (props.readOnlyDescription && props.description) {
      values.push(props.description)
    } else if (props.multipleSelect) {
      props.data.forEach((data: any) => {
        value.forEach((value_item: any) => {
          if (value_item.value === data[props.option.value]) {
            if (props.readOnlyFormat !== undefined && props.readOnlyFormat !== null) {
              values.push(
                <div className={props.data.length > 1 ? 'mb-3' : ''}>
                  {props.readOnlyFormat(data)}
                </div>
              )
            } else {
              values.push(
                <DefaultBadge type={'light'} className={'me-2'} key={getSelection()}>
                  {' '}
                  {props.option.name(data)}{' '}
                </DefaultBadge>
              )
            }
          }
        })
      })
    } else if (value) {
      // props.data.forEach((b: any) => {
      //   // if (b[props?.option?.value] === value[props?.option?.value]) {
      //   //   console.log(props.name, props.readOnlyFormat !== undefined, props.readOnlyFormat !== null)
      //   //   if (props.readOnlyFormat !== undefined && props.readOnlyFormat !== null) {
      //   //     values.push(props.readOnlyFormat(b))
      //   //   } else {
      //   //     values.push(props.option.name(b))
      //   //   }
      //   // }
      // })
      if (props.readOnlyFormat !== undefined && props.readOnlyFormat !== null) {
        values.push(props.readOnlyFormat(value))
      } else {
        values.push(props.option.name(value))
      }
    }

    if (values.length === 0) {
      // values.push(' - ')
      return null
    }

    return (
      <div className='row mb-5 align-items-center'>
        <InputLabel
          colSize={labelSize}
          className={`${labelClass.join(' ')} fw-semibold text-muted`}
          readOnly={props.readOnly}
        >
          {props.label}
        </InputLabel>
        <div className={inputAreaSize}>
          <div className={'row'}>
            <div className={`${inputSize} fv-row fv-plugins-icon-container`}>
              {props.readOnlyDescription && props.description ? (
                <div className={'form-text mt-0'}>{props.description}</div>
              ) : (
                <span className={`fs-6 ${inputBold ? 'fw-bold-custom' : ''}`}>
                  {values.map((item: any) => item)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={'row mb-6'}>
      {props.label ? (
        <InputLabel
          alignTop={props.description}
          className={labelClass.join(' ')}
          colSize={labelSize}
          required={props.required}
          inlineLabel={true}
        >
          {props.label}
        </InputLabel>
      ) : null}
      <div className={inputAreaSize}>
        <div className={'row'}>
          <div className={`${inputSize} fv-row fv-plugins-icon-container`}>
            <SelectBase
              search={props.search}
              name={props.name}
              availableSearch={props.availableSearch}
              values={props.values}
              multipleSelect={props.multipleSelect}
              changeValue={props.changeValue}
              data={props.data}
              option={props.option}
              isCreatable={isCreatable}
              disabled={isDisabled}
              placeholder={props.placeholder || ''}
              no_option_message={props.no_option_message || null}
            />
          </div>
          {props.formik && props.formik.errors[props.name] ? (
            <>
              <div className={`col-12 fv-row fv-plugins-icon-container`}>
                <div className='fv-plugins-message-container invalid-feedback'>
                  <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
                </div>
              </div>
            </>
          ) : null}

          {props.description ? (
            <div className={`col-12 fv-row fv-plugins-icon-container`}>
              <div className={'form-text'}>{props.description}</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
})

export default SelectInline
