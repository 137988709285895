import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {renderFullNameWithIcon} from '../../Helpers/User'

const Owner: FC<{label: string; user: any; link?: boolean}> = observer((props: any) => {
  const ownerLink = props.link ?? false
  return (
    <div className='mb-5'>
      <div className={'w-100 d-inline-flex align-items-center'}>
        <label className='col-4 col-print-5 fw-bold-custom text-muted'>{props.label}</label>
        <div className='col-8 col-print-7'>
          <span className='fw-bold-customer fs-6 text-dark'>
            {props.user ? renderFullNameWithIcon(props.user, false, false, ownerLink) : null}
          </span>
        </div>
      </div>
    </div>
  )
})
export default Owner
