import {observer} from 'mobx-react'
import * as React from 'react'
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import SectionTitle from '../../../Components/SectionTitle'
import {
  getRandomKey,
  getTranslation,
  hasModule,
  isNewMode,
  isReadOnlyMode,
  setEditMode,
} from '../../../Helpers/Default'
import DocumentStore from '../../../Stores/Document'
import {useFormik} from 'formik'
import CardLayout from '../../../Components/CardLayout'
import InputInline from '../../../Components/InputInline'
import {breadcrumbArray, formatBytes, getDocumentIcon} from '../../../Helpers/Document'
import SelectInline from '../../../Components/SelectInline'
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DOCUMENT_VISIBILITY_OPTIONS_MAP,
  DEFAULT_VISIBILITY_OPTIONS_MAP,
} from '../../../Constants/Defatul'
import {toJS} from 'mobx'
import UserStore from '../../../Stores/User'
import FolderStore from '../../../Stores/Folder'
import {getFullNameSimple, hasCrudRole, renderFullNameWithIcon} from '../../../Helpers/User'
import DateTimePickerDefault from '../../../Components/DateTimePicker'
import TextEditor from '../../../Components/TextEditor'
import {confirmDialog} from '../../../Helpers/ConfirmDialog'
import FormButtonFooter from '../../../Components/FormButtonFooter'
import {useHistory, useLocation} from 'react-router-dom'
import {checkFormErrors, checkValueError} from '../../../Helpers/Form'
import {isEmpty} from 'lodash'
import {DefaultButton} from '../../../Components/Buttons/DefaultButton'
import FileView from '../../../Components/FileView'
import {ACCEPT_READ_MODULE, DOCUMENTS_MODULE, EVENTS_MODULE} from '../../../Constants/Modules'
import SwitchInline from '../../../Components/SwitchInline'
import DefaultTableMui from '../../../Components/DefaultTableMui'
import {acceptReadsColumns} from '../../../Helpers/AcceptRead'
import useCheckCrudRole from '../../../Hooks/CheckCrudRole'

const DocumentDetail: FC<{documentData?: any; loadData?: any; match?: any}> = observer((props) => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  let id: any = null
  if (props.match && props.match.params && props.match.params.id) {
    id = props.match.params.id || null
  }

  if (props.documentData && props.documentData.file) {
    id = props.documentData.file.id
  }

  const [folders, setFolders] = React.useState<any>([])
  const [options, setOptions] = React.useState<any>([
    {value: null, id: null, label: 'asdf', level: 0},
  ])

  let returnBackParams = ''
  // @ts-ignore
  if (location?.state?.search) {
    // @ts-ignore
    returnBackParams = location.state.search
  }

  const [initialValues, setInitialValues] = React.useState<any>({
    readOnly: isReadOnlyMode(props.loadData),
    isNew: isNewMode(),
    id: id,
    name: '',
    type: '',
    description: '',
    isPublic: false,
    ownerId: null,
    folderId: '',
    attachment: null,
    owner: null,
    createdAt: null,
    updatedAt: null,
    acceptRead: false,
    key: getRandomKey(),
    breadCrumb: [],
    folder_simple: null,
    allAccepts: [],
  })

  useCheckCrudRole(DOCUMENTS_MODULE, `/dokumenty${returnBackParams}`)
  const getData = async (changeReadOnly = false) => {
    const data = await DocumentStore.get(id)

    let readOnly = initialValues.readOnly
    if (data && data.file) {
      let folderInit = {
        value: null,
        id: null,
        label: getTranslation('DOCUMENT.BREADCRUMB', intl),
        level: 0,
      }
      const breadCrumb = data.folder
      breadCrumb.unshift(folderInit)

      if (data.folder) {
        const b = data.folder[data.folder.length - 1]
        if (b.value !== null) {
          folderInit = {value: b.id, id: b.id, label: b.name, level: 0}
        }
      }
      const extend = data.file.name.split('.')
      if (changeReadOnly) {
        readOnly = true
      }

      setInitialValues({
        ...initialValues,
        readOnly: readOnly,
        id: data.file.id,
        key: getRandomKey(),
        extend: extend[extend.length - 1],
        title: data.file.name,
        acceptRead: data.file.acceptRead,
        name:
          data.file.type !== 'link'
            ? data.file.name.substring(0, data.file.name.lastIndexOf('.'))
            : data.file.name,
        orgFileName: data.file.name,
        type: data.file.type,
        description: data.file.description,
        isPublic:
          data.file.folder && !data.file.folder.isPublic && data.file.folder.ownerId
            ? {
                // @ts-ignore
                value: DEFAULT_DOCUMENT_VISIBILITY_OPTIONS_MAP[data.file.isPublic].value,
                // label: DEFAULT_VISIBILITY_OPTIONS_MAP[data.file.isPublic].label,
                label: getTranslation(
                  // @ts-ignore
                  DEFAULT_DOCUMENT_VISIBILITY_OPTIONS_MAP[data.file.isPublic].label,
                  intl
                ),
              }
            : {
                // @ts-ignore
                value: DEFAULT_VISIBILITY_OPTIONS_MAP[data.file.isPublic].value,
                // label: DEFAULT_VISIBILITY_OPTIONS_MAP[data.file.isPublic].label,
                label: getTranslation(
                  // @ts-ignore
                  DEFAULT_VISIBILITY_OPTIONS_MAP[data.file.isPublic].label,
                  intl
                ),
              },
        attachment: data.file.attachment,
        owner: data.file.owner,
        createdAt: data.file.createdAt,
        updatedAt: data.file.updatedAt,
        url: data.file.url,
        breadCrumb: breadCrumb,
        folder: folderInit,
        folder_simple: data.file.folder,
        allAccepts: data.allAccepts,
      })
    }
  }

  const flattenOptions = (tree: any, level = 0) => {
    let options: any[] = []

    tree.forEach((node: any) => {
      options.push({value: node.id, label: node.name, level})
      if (node.children) {
        options = options.concat(flattenOptions(node.children, level + 1))
      }
    })

    return options
  }

  const getFolders = async () => {
    const fetchFolders = await FolderStore.getFolderTree()
    setFolders(fetchFolders)
    const allFoldersOptions = flattenOptions(fetchFolders)
    allFoldersOptions.unshift({
      value: null,
      label: getTranslation('DOCUMENT.BREADCRUMB', intl),
      level: 0,
    })
    setOptions(allFoldersOptions)
  }

  useEffect(() => {
    if (!initialValues.isNew && id) {
      getData()
    }
    if (UserStore.users.length === 0) {
      UserStore.getAll()
    }
    getFolders()
  }, [])

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting, setErrors}) => {
      let errors = {}
      let isFocused = false
      checkValueError(
        'name',
        isEmpty(initialValues.name),
        isFocused,
        'FILE.FORM.VALIDATION.NAME',
        errors,
        intl
      )
      if (!checkFormErrors(errors, setErrors, setSubmitting)) {
        const data = {
          name:
            initialValues.type !== 'link'
              ? initialValues.name + '.' + initialValues.extend
              : initialValues.name,
          description: initialValues.description,
          isPublic: initialValues.isPublic.value,
          folderId: initialValues.folder.value,
          acceptRead: initialValues.acceptRead,
          id: initialValues.id,
        }
        if (await DocumentStore.update(initialValues.id, data)) {
          setInitialValues({...initialValues, readOnly: true})
          history.push(`/dokumenty/${initialValues.id}${returnBackParams}`)
          getData(true)
        }
      }
    },
  })

  const changeValue = (e: any) => {
    const {name, value} = e.target
    let initData = initialValues
    // @ts-ignore
    initData[name] = value
    setInitialValues({...initData})
  }

  const renderBreadCrumb = () => {
    const html = [
      <span key={getRandomKey()}>
        <span>{getTranslation('DOCUMENT.BREADCRUMB', intl)}</span>
      </span>,
    ]
    for (let i in initialValues.breadCrumb) {
      const folder = initialValues.breadCrumb[i]
      html.push(
        <span key={getRandomKey()}>
          <span>{folder.name}</span>
          {parseInt(i) < initialValues.breadCrumb.length - 1 ? breadcrumbArray() : null}
        </span>
      )
    }

    return html
  }

  const submit = async () => {
    confirmDialog('', getTranslation('FILE.UPDATE.QUESTION', intl), async () => {
      formik.handleSubmit()
    })
  }

  const getFileIcon = () => {
    return (
      <div className={'d-inline-flex align-items-center'}>
        {/*// @ts-ignore */}
        {getDocumentIcon(initialValues, initialValues.type)}
        {/*// @ts-ignore */}
        <div className={`document-name-detail`}>{initialValues.title}</div>
      </div>
    )
  }

  const removeAction = async () => {
    if (await DocumentStore.remove(initialValues.id)) {
      history.push(
        DocumentStore.back_url
          ? `/dokumenty${DocumentStore.back_url}`
          : `/dokumenty${returnBackParams}`
      )
    }
  }
  const pageButtons: any[] = []
  const extraStartRightButtons: any[] = []
  if (initialValues.readOnly && initialValues.type !== 'link') {
    extraStartRightButtons.push(
      <DefaultButton
        size={'sm'}
        color={'primary'}
        className={'d-inline-flex align-items-center me-3'}
        loading={DocumentStore.isDownloading}
        onClick={async () => {
          await DocumentStore.download(initialValues.id, initialValues.name, 'file')
        }}
        key={getRandomKey()}
      >
        {getTranslation('DOCUMENT.FORM.ACTION.DOWNLOAD', intl)}
      </DefaultButton>
    )
  }

  return (
    <>
      {!props.loadData ? (
        <>
          <PageTitle
            pageButtons={pageButtons}
            linkBack={
              DocumentStore.back_url
                ? `/dokumenty${DocumentStore.back_url}`
                : `/dokumenty${returnBackParams}`
            }
          >
            {getTranslation('DOCUMENT.TITLE.DETAIL', intl)}
          </PageTitle>
          <SectionTitle>{getFileIcon()}</SectionTitle>
          <FileView file={initialValues} />
        </>
      ) : null}
      <CardLayout>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='message_form'>
          <div
            className={`mx-auto w-100 mw-600px ${
              props.loadData ? '' : 'pt-15 pb-10'
            }  fv-plugins-bootstrap5 fv-plugins-framework`}
          >
            <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
              <div className='mb-5'>
                <h2 className='fw-bold-custom text-dark border-bottom w-100'>
                  {getTranslation('FILE.FORM.TITLE.INFORMATION', intl)}
                </h2>
              </div>
              <div>
                {initialValues.type === 'link' ? (
                  <>
                    <InputInline
                      id={'url'}
                      name={'url'}
                      inputBold={true}
                      value={initialValues.url}
                      readOnlyFormat={(value: any) => {
                        return (
                          <a
                            href={
                              initialValues.url.indexOf('http') > -1
                                ? initialValues.url
                                : 'https://' + initialValues.url
                            }
                            className={'fw-bold-custom'}
                            target={'_blank'}
                            rel='noreferrer'
                          >
                            {initialValues.url}
                          </a>
                        )
                      }}
                      required={true}
                      label={getTranslation('FILE.FORM.LINK', intl)}
                      formik={formik}
                      changeValue={changeValue}
                      readOnly={initialValues.readOnly}
                    />
                  </>
                ) : null}
                <InputInline
                  id={'name'}
                  name={'name'}
                  value={initialValues.name}
                  readOnlyFormat={(value: any) => {
                    if (props.loadData) {
                      return (
                        <div
                          className='cursor-pointer link-primary fw-bold-custom'
                          onClick={async () => {
                            await DocumentStore.download(
                              initialValues.id,
                              initialValues.name,
                              'file'
                            )
                          }}
                        >
                          {initialValues.orgFileName}
                        </div>
                      )
                    }
                    return <div className={'fw-bold-custom'}>{initialValues.orgFileName}</div>
                  }}
                  required={true}
                  label={getTranslation('FILE.FORM.NAME', intl)}
                  formik={formik}
                  changeValue={changeValue}
                  readOnly={initialValues.readOnly}
                  onBlur={(e: any) => {
                    changeValue(e)
                  }}
                  inputBold={true}
                  groupAfterText={
                    initialValues.type !== 'link' ? '.' + initialValues.extend || '' : ''
                  }
                />
                <SelectInline
                  key={initialValues.breadCrumb.length + '-folder'}
                  name={'folder'}
                  availableSearch={true}
                  readOnlyFormat={(value: any) => {
                    return renderBreadCrumb()
                  }}
                  readOnly={initialValues.readOnly}
                  changeValue={changeValue}
                  label={getTranslation('FILE.FORM.FOLDER', intl)}
                  data={options}
                  option={{
                    value: 'value',
                    name: function (value: any) {
                      // return value['label']
                      return (
                        <div className={' ps-3'} style={{marginLeft: `${value['level'] * 10}px`}}>
                          {value['label']}
                        </div>
                      )
                    },
                  }}
                  values={initialValues}
                />
                <SelectInline
                  name={'isPublic'}
                  values={initialValues}
                  label={getTranslation('FILE.FORM.VISIBILITY', intl)}
                  formik={formik}
                  changeValue={changeValue}
                  readOnly={initialValues.readOnly}
                  data={Object.values(
                    initialValues.folder_simple &&
                      !initialValues.folder_simple.isPublic &&
                      initialValues.folder_simple.ownerId
                      ? DEFAULT_DOCUMENT_VISIBILITY_OPTIONS_MAP
                      : DEFAULT_VISIBILITY_OPTIONS_MAP
                  ).map((item: any) => {
                    return {
                      value: item.value,
                      label: getTranslation(item.label, intl),
                    }
                  })}
                  option={{
                    value: 'value',
                    name: function (value: any) {
                      return value['label']
                    },
                  }}
                />
                {hasModule(ACCEPT_READ_MODULE) &&
                ((initialValues.acceptRead && initialValues.readOnly) ||
                  !initialValues.readOnly) ? (
                  <SwitchInline
                    label={getTranslation('FILE.FROM.ACCEPT_READ', intl)}
                    columnLabel={true}
                    name={'acceptRead'}
                    value={initialValues.acceptRead}
                    // label={
                    //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                    // }
                    formik={formik}
                    changeValue={changeValue}
                    readOnly={initialValues.readOnly}
                    texts={{
                      select: getTranslation('BASE.YES', intl),
                      unselect: getTranslation('BASE.NO', intl),
                    }}
                  />
                ) : null}
                {initialValues.attachment && initialValues.attachment.fileSize ? (
                  <InputInline
                    id={'size'}
                    name={'size'}
                    // @ts-ignore
                    value={formatBytes(initialValues.attachment.fileSize)}
                    label={getTranslation('FILE.FORM.SIZE', intl)}
                    formik={formik}
                    changeValue={changeValue}
                    readOnly={true}
                    hide={!initialValues.readOnly}
                  />
                ) : null}
                <SelectInline
                  name={'owner'}
                  values={initialValues}
                  label={getTranslation('FILE.FORM.AUTHOR', intl)}
                  formik={formik}
                  changeValue={changeValue}
                  data={toJS(UserStore.users)}
                  readOnly={true}
                  hide={!initialValues.readOnly}
                  readOnlyFormat={(value: any) => {
                    return renderFullNameWithIcon(value, false, false, true)
                  }}
                  option={{
                    value: 'id',
                    name: function (value: any) {
                      return getFullNameSimple(value)
                    },
                  }}
                />
                <DateTimePickerDefault
                  name={'createdAt'}
                  value={initialValues.createdAt}
                  required={false}
                  label={getTranslation('FILE.FORM.CREATED_AT', intl)}
                  formik={formik}
                  changeValue={changeValue}
                  displayFormat={DEFAULT_DATE_TIME_FORMAT}
                  readOnly={true}
                  hide={!initialValues.readOnly}
                />
                <DateTimePickerDefault
                  name={'updatedAt'}
                  value={initialValues.updatedAt}
                  required={false}
                  label={getTranslation('FILE.FORM.UPDATED_AT', intl)}
                  formik={formik}
                  changeValue={changeValue}
                  displayFormat={DEFAULT_DATE_TIME_FORMAT}
                  readOnly={true}
                  hide={!initialValues.readOnly}
                />
              </div>
              <div className='d-flex flex-column mt-5 mb-8 fv-row fv-plugins-icon-container'>
                <SectionTitle>{getTranslation('FILE.FORM.DESCRIPTION', intl)}</SectionTitle>
                <TextEditor
                  name={'description'}
                  key={initialValues.key}
                  isEdit={!initialValues.readOnly}
                  value={initialValues.description}
                  action={changeValue}
                  formik={formik}
                  required={true}
                  hide={!initialValues.readOnly}
                  emptyText={getTranslation('FILE.DESCRIPTION.EMPTY', intl)}
                />
              </div>

              {initialValues.readOnly && initialValues.acceptRead && !props.documentData ? (
                <div className={'mb-5'}>
                  <div className='mb-5 mt-5'>
                    <h2 className='fw-bold-custom text-dark border-bottom w-100'>
                      {getTranslation('CALENDAR.FORM.ACCEPT_READ.TITLE', intl)}
                    </h2>
                  </div>
                  <div>
                    <DefaultTableMui
                      pagination={false}
                      data={initialValues.allAccepts.sort((a: any, b: any) => {
                        if (a.isRead === b.isRead) {
                          return a.user.lastName
                            .toLowerCase()
                            .localeCompare(b.user.lastName.toLowerCase())
                        }
                        return a.isRead ? 1 : -1
                      })}
                      columns={acceptReadsColumns(intl)}
                      showColumnFilters={false}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {!props.loadData ? (
              <FormButtonFooter
                id={initialValues.id}
                linkBack={
                  DocumentStore.back_url
                    ? `/dokumenty${DocumentStore.back_url}`
                    : `/dokumenty${returnBackParams}`
                }
                deleteText={'FILE.FORM.BUTTONS.DELETE'}
                changeReadOnly={(e: any) => {
                  setEditMode(e, 'dokumenty', history, initialValues, setInitialValues)
                }}
                deleteAction={() =>
                  confirmDialog(
                    '',
                    getTranslation(
                      initialValues.acceptRead
                        ? 'FILE.REMOVE.QUESTION.ACCEPT_READ'
                        : 'FILE.REMOVE.QUESTION',
                      intl
                    ),
                    removeAction
                  )
                }
                textLinkBack={'FILE.FORM.BUTTONS.BACK'}
                changeToEdit={(e: any) => {
                  setEditMode(e, 'dokumenty', history, initialValues, setInitialValues)
                }}
                readOnly={initialValues.readOnly}
                showEditButton={true}
                editButtonText={'FILE.FORM.BUTTONS.EDIT'}
                changeButtonText={'FILE.FORM.BUTTONS.CHANGE'}
                createButtonText={'FILE.FORM.BUTTONS.CREATE'}
                submit={submit}
                showCRUDButtons={hasCrudRole(DOCUMENTS_MODULE)}
                loader={DocumentStore.isCreating || DocumentStore.isUpdating}
                extraStartRightButtons={extraStartRightButtons}
              />
            ) : null}
          </div>
        </form>
      </CardLayout>
    </>
  )
})

export default DocumentDetail
