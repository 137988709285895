import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {getRandomKey, getTranslation} from '../../Helpers/Default'
import SectionTitle from '../SectionTitle'
import {useIntl} from 'react-intl'
import DocumentsModal from './Documents'
import {DefaultButton} from '../Buttons/DefaultButton'
import AttachmentsModal from './Attachments'
import {cloneDeep} from 'lodash'
import DocumentStore from '../../Stores/Document'
import {getDocumentIcon} from '../../Helpers/Document'
import EmptyText from '../EmptyText'
import VisibilityIcon from '../VisibilityIcon'

const FileManager: FC<{
  className?: string
  selectedFiles: any
  setFiles: any
  selectedAttachments: any
  setAttachments: any
  availableFiles: boolean
  availableAttachments: boolean
  readOnly: boolean
  hasCrudRole?: boolean
  hideSectionTitle?: boolean
  showAttachmentModal?: boolean
  showIcon?: boolean
}> = observer(
  ({
    className,
    selectedFiles,
    setFiles,
    selectedAttachments,
    setAttachments,
    availableFiles = false,
    availableAttachments = false,
    readOnly = false,
    hasCrudRole = false,
    hideSectionTitle = false,
    showAttachmentModal = false,
    showIcon = false,
  }) => {
    const intl = useIntl()
    const [documentModalShow, setDocumentModalShow] = React.useState(false)
    const [attachmentsModalShow, setAttachmentsModalShow] = React.useState(showAttachmentModal)
    const [pomFiles, setPomFiles] = React.useState<any>(selectedFiles)
    const [pomAttachments, setPomAttachments] = React.useState<any>(selectedAttachments)

    const setDocuments = (files: any) => {
      setPomFiles(cloneDeep(files))
      setFiles(cloneDeep(files))
    }

    const setAttachmentsPom = (files: any) => {
      setPomAttachments([...pomAttachments, ...files])
      setAttachments([...pomAttachments, ...files])
    }

    const showDocumentModal = () => {
      setDocumentModalShow(!documentModalShow)
    }

    const showAttachmentsModal = () => {
      setAttachmentsModalShow(!attachmentsModalShow)
    }

    const setRemoveAttachmentsPom = (files: any) => {
      setPomAttachments(files)
      setAttachments(files)
    }

    const renderSelectedFiles = () => {
      if (pomFiles.length === 0 && pomAttachments.length === 0) return
      return (
        <div className='table-responsive w-100'>
          <table className='table w-100 align-middle dataTable'>
            <thead>
              <tr>
                <th className={'fw-bold-custom'}>{getTranslation('FILE.FORM.NAME', intl)}</th>
                {hasCrudRole ? (
                  <th className={'fw-bold-custom'} style={{width: '80px'}}>
                    {getTranslation('FILE.TABLE.VISIBILITY', intl)}
                  </th>
                ) : null}
                <th className={'fw-bold-custom'} style={{width: '80px'}}>
                  {getTranslation('BASE.ACTION', intl)}
                </th>
              </tr>
            </thead>
            <tbody>
              {pomFiles.map((file: any, index: number) => {
                if (!hasCrudRole && !file.isPublic) return null
                return (
                  <tr key={file.id}>
                    <td>
                      {getDocumentIcon(file, file.type)}
                      <span className={'fw-bold-custom'}>{file.name}</span>
                    </td>
                    {hasCrudRole ? (
                      <td>
                        <VisibilityIcon isVisible={file.isPublic} />
                      </td>
                    ) : null}
                    {!readOnly ? (
                      <td>
                        <DefaultButton
                          onClick={() => {
                            const filterFiles = pomFiles.filter(
                              (f: any, indexFiles: number) => index !== indexFiles
                            )
                            setDocuments(filterFiles)
                          }}
                          size={'sm'}
                          key={1}
                          style={'light'}
                          color={'danger'}
                          icon={<i className='fa fa-trash'></i>}
                        ></DefaultButton>
                      </td>
                    ) : (
                      <td>
                        <DefaultButton
                          onClick={() => {
                            DocumentStore.download(file.id, file.name, 'file')
                          }}
                          size={'sm'}
                          key={1}
                          style={'light'}
                          color={'success'}
                          icon={<i className='fa fa-file-download'></i>}
                        ></DefaultButton>
                      </td>
                    )}
                  </tr>
                )
              })}
              {pomAttachments.map((file: any, fileIndex: boolean) => {
                return (
                  <tr key={getRandomKey()}>
                    <td className={'fw-bold-custom'}>
                      <div className='d-flex align-items-center'>
                        {getDocumentIcon(file, file.file ? 'upload_file' : 'attachment')}
                        {file.file ? file.file.name : file.name}
                      </div>
                    </td>
                    {hasCrudRole ? (
                      <td>
                        <VisibilityIcon isVisible={true} />
                      </td>
                    ) : null}
                    {!readOnly ? (
                      <td>
                        <div className='d-flex align-items-center'>
                          <DefaultButton
                            onClick={() => {
                              const filterFiles = pomAttachments.filter(
                                (f: any, index: boolean) => fileIndex !== index
                              )
                              setRemoveAttachmentsPom(filterFiles)
                            }}
                            size={'sm'}
                            key={1}
                            style={'light'}
                            color={'danger'}
                            icon={<i className='fa fa-trash'></i>}
                          ></DefaultButton>
                        </div>
                      </td>
                    ) : (
                      <td>
                        <DefaultButton
                          onClick={() => {
                            DocumentStore.download(file.id, file.name, 'attachment')
                          }}
                          size={'sm'}
                          key={1}
                          style={'light'}
                          color={'success'}
                          icon={<i className='fa fa-file-download'></i>}
                        ></DefaultButton>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }

    const actionButtons = () => {
      let buttons = []
      if (availableFiles) {
        buttons.push(
          <DefaultButton
            onClick={showDocumentModal}
            size={'sm'}
            style={'light'}
            key={1}
            color={'primary'}
          >
            {getTranslation('FILE_MANAGER.SELECT_DOCUMENT', intl)}
          </DefaultButton>
        )
      }

      if (availableAttachments) {
        buttons.push(
          <DefaultButton
            onClick={showAttachmentsModal}
            size={'sm'}
            style={'light'}
            key={2}
            color={'primary'}
            className={'ms-3'}
          >
            {getTranslation('FILE_MANAGER.UPLOAD_ATTACHMENT', intl)}
          </DefaultButton>
        )
      }

      return buttons
    }
    return (
      <div className={className ?? ''}>
        {hideSectionTitle ? null : (
          <SectionTitle buttons={!readOnly ? actionButtons() : []}>
            {getTranslation('FILE_MANAGER.TITLE', intl)}
          </SectionTitle>
        )}

        {pomAttachments.length === 0 && pomFiles.length === 0 ? (
          <EmptyText text={getTranslation('FILE_MANAGER.NO_ATTACHMENTS', intl)} />
        ) : null}

        <DocumentsModal
          show={documentModalShow}
          closeModal={showDocumentModal}
          selectedFiles={selectedFiles}
          setFiles={setDocuments}
        />
        <AttachmentsModal
          show={attachmentsModalShow}
          closeModal={showAttachmentsModal}
          selectedAttachments={selectedAttachments}
          setAttachments={setAttachmentsPom}
        />
        {renderSelectedFiles()}
      </div>
    )
  }
)

export default FileManager
