import {observer} from 'mobx-react'
import React, {FC} from 'react'
import clsx from 'clsx'
import Select, {components} from 'react-select'
import {DefaultBadge} from '../Badge'
import SelectBase from '../SelectBase'

const SelectColumn: FC<{
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  type?: string
  changeValue: any
  data: any
  option: any
  multipleSelect?: boolean
  values: any
  readOnly?: boolean
  description?: any
  availableSearch?: boolean
  readOnlyDescription?: boolean
  isCreatable?: boolean
  readOnlyFormat?: any
  inputBold?: boolean
  disableMargin?: boolean
  search?: any
}> = observer((props: any) => {
  let labelClass = ['fw-bold-custom fw-bold-custom form-label']
  let inputClass = ['form-select', 'form-select-lg', 'form-control-solid'] //"form-select-solid"
  let inputBold = props.inputBold ?? false
  let disableMargin = props.disableMargin ?? false
  const isCreatable = props.isCreatable ? props.isCreatable : false
  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  if (props.readOnly) {
    let value = props.values[props.name]
    if (value && props.option.value !== 'function' && value[props.option.value]) {
      value = value[props.option.value]
    }
    let values: any = []
    if (props.readOnlyDescription && props.description) {
      values.push(props.description)
    } else if (props.multipleSelect) {
      props.data.forEach((data: any) => {
        value.forEach((value_item: any) => {
          if (value_item.value === data[props.option.value]) {
            if (props.readOnlyFormat !== undefined && props.readOnlyFormat !== null) {
              values.push(props.readOnlyFormat(data))
            } else {
              values.push(
                <DefaultBadge type={'light'} className={'me-2'} key={getSelection()}>
                  {' '}
                  {props.option.name(data)}{' '}
                </DefaultBadge>
              )
            }
          }
        })
      })
    } else {
      props.data.forEach((b: any) => {
        if (b[props.option.value] === value) {
          if (props.readOnlyFormat !== undefined && props.readOnlyFormat !== null) {
            values.push(props.readOnlyFormat(b))
          } else {
            values.push(props.option.name(b))
          }
        }
      })
    }

    if (values.length === 0) {
      values.push(' - ')
    }
    return (
      <div className='mb-5 h-40px'>
        <div className={'w-100 d-inline-flex align-items-center h-100'}>
          {props.label ? (
            <label className='col-5 fw-bold-custom text-muted'>{props.label}</label>
          ) : null}
          <div className='col-7'>
            {props.readOnlyDescription && props.description ? (
              <div className={'form-text mt-0'}>{props.description}</div>
            ) : (
              <span className={`${inputBold ? 'text-dark fw-bold-customer' : ''} fs-6`}>
                {values.map((item: any) => item)}
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`${!disableMargin ? 'mb-5' : null}`}>
      {props.label ? (
        <label className='d-flex align-items-center mb-2'>
          <span className={labelClass.join(' ')}>{props.label}</span>
          {/*<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"*/}
          {/*   aria-label="Specify a target name for future usage and reference"*/}
          {/*   data-kt-initialized="1"></i>*/}
        </label>
      ) : null}
      {/*{props.multipleSelect ? (*/}
      <SelectBase
        name={props.name}
        availableSearch={props.availableSearch}
        values={props.values}
        multipleSelect={props.multipleSelect}
        changeValue={props.changeValue}
        data={props.data}
        option={props.option}
        isCreatable={isCreatable}
        search={props.search}
      />

      {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
        </div>
      )}

      {props.description ? <div className={'form-text'}>{props.description}</div> : null}
    </div>
  )
})

export default SelectColumn
